import React from 'react';
// Form component
import ContactForm from '../components/contactForm';

function Contact() {
  return (    
    <ContactForm></ContactForm>
  );
}

export default Contact;
